import React from 'react'
import styled from 'styled-components'
import { StaticImage } from 'gatsby-plugin-image'

import ContentContainer from '../UI/ContentContainer'

const AwardListInHeader = ({children}) => {

  return (
    <ContentContainer padding={"40px 30px"} mPadding="40px 24px" sPadding="40px 20px" xsPadding="0px 20px 40px 20px">
      <Wrapper>
        {children}
        <AwardListForDesktop>
          <StaticImage src='../../assets/images/AwardListInHeader.png' alt="AwardListInHeader" placeholder='none' loading='eager' />
        </AwardListForDesktop>
        <AwardListForMobile>
          <StaticImage src='../../assets/images/PLAwardList.png' alt="PLAwardList" placeholder='none' loading='eager' />
        </AwardListForMobile>
      </Wrapper>
    </ContentContainer>
  )
}
const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items:center;
  width: 100%;
  p {
    width: 160px;
    min-width: 160px;
	  max-width: 160px;
    margin: 20px 0;
    flex-grow: 0;
    text-align: center;
  }
  @media screen and (max-width: 991px) {
    flex-direction: column;
    p {
      width: 320px;
      min-width: 320px;
      max-width: 320px;

    }
  } ;


`
const AwardListForDesktop = styled.div`
  margin: 0 auto;
  width: 100%;
  @media screen and (max-width: 768px) {
    display: none;
  }
`
const AwardListForMobile = styled.div`
  display: none;
@media screen and (max-width: 768px) {
  display: block;
  margin: 0 auto;
  width: 100%;
}
`

export default AwardListInHeader
